import React, { useEffect } from 'react';
import firebase from './firebase'; // Adjust the import path as necessary

const OrderListener = () => {
  useEffect(() => {
    const orderRef = firebase.database().ref('Plus View');

    // Request notification permission if not already granted
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }

    const handleNewOrder = (snapshot) => {
      const newOrder = snapshot.val();
      if (!newOrder) return; // Early exit if no data

      console.log('New order placed:', newOrder);

      // Show browser notification
      if (Notification.permission === 'granted') {
        new Notification('New Order Alert', {
          body: `Order placed by ${newOrder.company}. Details: ${newOrder.grade}, ${newOrder.order} units, ${newOrder.payment} amount.`,
          // Optional: Add icon if needed
          // icon: '/path-to-your-icon.png'
        });
      } else {
        console.log('Notification permission not granted.');
      }
    };

    orderRef.on('child_added', handleNewOrder);

    // Cleanup listener when component unmounts
    return () => {
      orderRef.off('child_added', handleNewOrder);
    };
  }, []);

  return <div>Listening for new orders...</div>;
};

export default OrderListener;
