
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate,useNavigate } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import Rates from './Components/Ratelist';
import Finance from './Components/Finance';
import Machinery from './Components/Machinery';
import OrderStatus from './Components/OrderStatus';
import Careers from './Components/career';
import Vendor from './Components/vendor';
import Footer from './Components/Footer';
import Profile from './Components/Profile';
import NewProfile from './Components/NewProfile'; // Import the NewProfile component
import LoginPage from './Firebase/Login';
import RawMaterials from './Components/RawMat';
import CementForm from './Forms/CementForm';
import SteelForm from './Forms/SteelForm';
import VendorForm from './Forms/vendorForm';
import MachineryForm from './Forms/MachineryForm';
import firebase from './Firebase/firebase';
import AboutUs from './Components/FooterItems/AboutUs';
import Terms from './Components/FooterItems/TermsOfUse';
import Service from './Components/FooterItems/OurServices';
import Understanding from './Components/FooterItems/ProcessUnderstand';
import Privacy from './Components/FooterItems/privacy-policy';
import Return from './Components/FooterItems/Returnplcy';
import Contactus from './Components/FooterItems/Contactus';
import LandingPage from './Components/landingPage';
import ChatBox from './Components/chatbox.js';
import NewSite from './Components/newsite.js';
import ChatWeb from './Components/ChatWeb.js';
import InteractWhatsapp from './Components/FooterItems/InteractWhatsapp.js';
import ClientDetail from './Components/clientDetail.js';
import StockCheck from './Components/StockCheck.js';
import ChatBot from './Components/ChatBot.js';
import DeleteAccount from './Components/DeleteAccount.js';
import OrderListener from './Firebase/OrderListner.js';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Initially null to handle loading state
  const navigate = useNavigate();
 const [showChat, setShowChat] = useState(false);

 useEffect(() => {
   const handlePageLoad = () => {
     let timeout;
     clearTimeout(timeout);
     timeout = setTimeout(() => {
       window.dispatchEvent(new Event('resize'));
     }, 250);
   };

   window.addEventListener('load', handlePageLoad);

   return () => {
     window.removeEventListener('load', handlePageLoad);
   };
 }, []);

 useEffect(() => {
  const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    setIsAuthenticated(!!user);
  });
  return () => unsubscribe();
}, []);

 const handleLinkClick = () => {};
 const handleChatButtonClick = () => {
  setShowChat(true);
};
  const logout = () => {
    firebase.auth().signOut().then(() => {
      setIsAuthenticated(false);
      navigate('/login'); // Redirect to login after logout
    });
  };

  if (isAuthenticated === null) {
    return ;
  }

 return (
    <div style={{ paddingTop: '70px' }}>
      <Header isAuthenticated={isAuthenticated} logout={logout} />
      
      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <LoginPage setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/Home" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
        <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/login" />} />
        <Route path="/newprofile" element={isAuthenticated ? <NewProfile /> : <Navigate to="/login" />} />
        <Route path="/delete-account" element={isAuthenticated ? <DeleteAccount /> : <Navigate to="/login" />} />

        {[
          { path: "/raw-materials", component: <RawMaterials /> },
          { path: "/rates", component: <Rates /> },
          { path: "/cement-order", component: <CementForm /> },
          { path: "/steel-order", component: <SteelForm /> },
          { path: "/machinery", component: <MachineryForm /> },
          { path: "/machinerypage", component: <Machinery /> },
          { path: "/finance", component: <Finance /> },
          { path: "/status", component: <OrderStatus /> },
          { path: "/vendor", component: <Vendor /> },
          { path: "/vendorsform", component: <VendorForm /> },
          { path: "/newsite", component: <NewSite /> },
           { path: "/stock", component: <StockCheck /> },
        ].map(({ path, component }) => (
          <Route key={path} path={path} element={isAuthenticated ? component : <Navigate to="/login" />} />
        ))}

        {/* Footer Pages */}
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/termsofuse" element={<Terms />} />
        <Route path="/ourservices" element={<Service />} />
        <Route path="/processofunderstanding" element={<Understanding />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/return" element={<Return />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/InteractWhatsapp" element={<InteractWhatsapp />} />
        <Route path="/clientDetail" element={<ClientDetail />} />
      </Routes>

      <Footer />
      <div className='msg-container'>
        <ChatWeb />
      </div>
      <OrderListener />
    </div>
  );
}

// Wrap App with Router
function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;